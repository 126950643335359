<template>
  <div>
    <div style="width:100%;margin-bottom:1em;display:inline-flex;">
      <b-button class="btn-fill" size="sm" variant="success" @click="taskSave()">DONE</b-button>
      <h4 style="margin:0 0 0 1em;padding:0;">
        <span style="font-size:.85em;"><span style="font-weight:600;font-size:.8em;margin-right:1em;">({{ ticketNumber }}-{{ task.stepId }})</span>{{ title }}</span></h4>
    </div>

    <form @input="dataChanged()">

      <div class="row">
        <!--        <div class="col-12">-->
        <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9>">
          <sf-input type="text" :disabled="isReadOnly()" :labelCols="2" label="Title" cstyle="margin-bottom:1em;width:90%;font-weight:800;" v-model="data.title" />
          <!--          <sf-input type="textarea" :readonly="isReadOnly()" :labelCols="1" label="Description" :rows="5" id="description" v-model="data.description"></sf-input>-->
        </div>

        <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9>">
          <sf-input type="number" :disabled="isReadOnly()" :labelCols="2" cstyle="width:10em;" label="Sequence" v-model="data.sequence" />
          <sf-input type="list" :disabled="isReadOnly()" :labelCols="2" cstyle="width:15em;" label="Category" :options="categories" v-model="data.category" />
          <template v-if="isProject">
            <sf-input type="list" :disabled="isReadOnly()" :labelCols="2" cstyle="width:15em;" label="Budget Year" :options="budgetYears" v-model="data.budgetYear" />
            <!-- <sf-input type="list" :disabled="isReadOnly()" :labelCols="2" cstyle="width:90%;" label="Project" :options="projectList" v-model="data.projectId" /> -->
          </template>
          <sf-input type="date" :disabled="isReadOnly()" :labelCols="2" label="Scheduled Begin" cstyle="width:15em;" v-model="data.scheduledDate" />
          <sf-input type="list" :disabled="isReadOnly()" :labelCols="2" cstyle="margin-bottom:1em;width:90%;" label="Depends On" :options="taskList" v-model="data.dependsOn" />
          <sf-input type="icon-text" :disabled="true" :labelCols="2" label="Vendor" appendIcon="fa fa-search show-hand" @appendClick="taskSearchVendor()" cstyle="width:25em;" v-model="data.vendorName" />
          <sf-input type="textarea" :readonly="isReadOnly()" :labelCols="2" label="Description" :rows="5" id="description" v-model="data.description"></sf-input>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3>">
          <div style="width:100%;">
            <b-checkbox v-if="!isEditable && $root.isAdmin()" v-model="isEditable" style="margin-left:8.2vw;" >Editable</b-checkbox>
            <b-checkbox v-model="data.complete" style="margin-left:8.2vw;" @change="toggleComplete(data)">Complete</b-checkbox>
            <template v-if="data.complete">
              <sf-input type="date" cstyle="width:18em;" label="Date Completed" :labelCols="5" v-model="data.completedAt"></sf-input>
              <sf-input type="text" cstyle="width:18em;border-bottom:3px solid black;" label="Completed By" :labelCols="5" v-model="data.completedBy"></sf-input>
            </template>

            <!-- <b-checkbox v-model="data.billed" style="margin-left:8.2vw;margin-top:1em" >Billed</b-checkbox>
            <template v-if="data.billed">
              <sf-input type="date" cstyle="width:12em;" label="Billed Date" :labelCols="3" v-model="data.billedAt"></sf-input>
              <sf-input type="text" cstyle="width:12em;border-bottom:3px solid black;" label="Ref #" :labelCols="3" v-model="data.billedInoice"></sf-input>
            </template>

            <b-checkbox v-model="data.paid" style="margin-left:8.2vw;margin-top:1em" >Paid</b-checkbox>
            <template v-if="data.paid">
              <sf-input type="date" cstyle="width:12em;" label="Paid Date" :labelCols="3" v-model="data.paidAt"></sf-input>
              <sf-input type="text" cstyle="width:12em;border-bottom:3px solid black;" label="Ref #" :labelCols="3" v-model="data.paidInoice"></sf-input>
            </template>  -->

          </div>
        </div>
        <!--        <div class="col-12">-->
        <!--          <sf-input type="textarea" :readonly="isReadOnly()" :labelCols="0" label="Description" :rows="5" id="description" v-model="data.description"></sf-input>-->
        <!--        </div>-->
      </div>
      <div style="width100%;">
        <h4>Financial Values</h4>
      </div>

      <table style="width:100%;background:#eee;border-radius:1em;borde:1px solid black;padding:1em;">
        <thead style="text-align:center;font-size:1.25em;font-weight:800;background:black;color:white;">
        <td>Actual</td>
        <td v-if="isBudget">
          Estimated/Budget
          <i v-if="$root.isAdmin() && !isReadOnly() && !canEditBudget()" class="fa fa-lock show-hand" style="display:relative;float:right;padding-top:4px;font-size:1em;margin-right:.5em;font-weight:100;color:white;" @click="isBudgetEditOverride=true" v-b-popover.hover.top="'Override budget editing'"></i>
          <i v-if="$root.isAdmin() && !isReadOnly() && canEditBudget()" class="fa fa-unlock show-hand" style="display:relative;float:right;padding-top:4px;font-size:1em;margin-right:.5em;font-weight:100;color:white;" @click="isBudgetEditOverride=false" v-b-popover.hover.top="'Lock budget editing'"></i>

        </td>
        </thead>
        <tr>&nbsp;</tr>
        <tr>
          <td>
            <sf-input type="number" :disabled="isReadOnly()" label="Hours" cstyle="width:6em;" v-model="data.hours" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Labor Rate" cstyle="width:12em;" v-model="data.laborRate" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Labor" cstyle="width:12em;" v-model="data.labor" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Parts" cstyle="width:12em;" v-model="data.parts" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Supplies" cstyle="width:12em;" v-model="data.supplies" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Expenses" cstyle="width:12em;" v-model="data.expenses" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly()" label="Outside" cstyle="width:12em;" v-model="data.outside" />
          </td>
          <td v-if="isBudget">
            <sf-input type="number" :disabled="isReadOnly() || !canEditBudget()" label="Hours" cstyle="width:6em;" v-model="data.estimatedHours" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly() || !canEditBudget()" label="Labor" cstyle="width:12em;" v-model="data.estimatedLabor" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly() || !canEditBudget()" label="Parts" cstyle="width:12em;" v-model="data.estimatedParts" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly() || !canEditBudget()" label="Supplies" cstyle="width:12em;" v-model="data.estimatedSupplies" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly() || !canEditBudget()" label="Expenses" cstyle="width:12em;" v-model="data.estimatedExpenses" />
            <sf-input type="comma" isCurrency :disabled="isReadOnly() || !canEditBudget()" label="Outside" cstyle="width:12em;margin-bottom:2.5em;" v-model="data.estimatedOutsideTotal" />
          </td>
          <tr style="border-top:1px solid lightgray;">
            <td><sf-input :readonly="true" type="comma" label="Total" :label-cols="4" cstyle="width:12em;"  isCurrency v-model="taskTotal"/></td>
            <td><sf-input :readonly="true" type="comma" label="Total" :label-cols="4" cstyle="width:12em;"  isCurrency v-model="estimatedTotal"/></td>
          </tr>
        </tr>
      </table>
    </form>

    <b-modal class="modal-scoped fade" id="taskVendorLookupModal" v-model="taskVendorLookupModalShow" title="Find Vendor">

      <div style="">
        <live-search
          style="width:100%;"
          :searchItem="vendorSearchField"
          :searchMethod="vendorSearch"
          valueField="_id"
          displayField="name"
          @select="assignVendorToTask($event)"
        >
          <template v-slot:list-item="suggestion">
            <div>
              {{ suggestion.accountNumber }}:{{ suggestion.name }} <span v-if="suggestion.primary.city">/ {{ suggestion.primary.city }}</span><span v-if="suggestion.primary.state">, {{
                suggestion.primary.state
              }}</span>
            </div>
          </template>
        </live-search>
      </div>

      <template slot="modal-footer" slot-scope="{ cancel }">
        <b-button class="btn-fill" size="sm" variant="danger" @click="taskVendorLookupModalShow=false">
          Cancel
        </b-button>
        <b-button class="btn-fill" size="sm" variant="info" @click="removeVendorFromTask()">
          Unlink Vendor
        </b-button>
        <b-button class="btn-fill" size="sm" variant="warning" @click="removeVendorFromTask()">
          Add Vendor
        </b-button>
      </template>
    </b-modal>

  </div>

</template>
<script>

import PieWidget     from "/src/components/UIComponents/Widgets/PieWidget.vue";
import BarWidget     from "/src/components/UIComponents/Widgets/BarWidget.vue";
import VendorService from "/src/Services/VendorService";

import Currency   from "currency.js";
import gravatar   from "gravatar";
import Moment     from "moment";
import Card       from "/src/components/UIComponents/Cards/Card.vue";
import SfInput    from "/src/components/UIComponents/Inputs/SFormInput.vue";
import LiveSearch from "/src/components/UIComponents/Inputs/LiveSearch.vue";

import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
  components: {
    Card,
    SfInput,
    VueSimpleSuggest,
    PieWidget,
    BarWidget,
    LiveSearch
  },
  props     : {
    task            : {type: Object, default: () => {}},
    ticketNumber    : {type: Number, default: 0},
    title           : {type: String, default: "Task"},
    categories      : {type: Array, default: () => []},
    isEditable      : {type: Boolean, default: true},
    isBudget        : {type: Boolean, default: false},
    isProject       : {type: Boolean, default: false},
    projectList     : {type: Array, default: () => []},
    enableBudgetEdit: {type: Boolean, default: true},
    budgetYear      : {type: String, default: ""},
    budgetYears     : {type: Array, default: () => [] },
    taskList        : {type: Array, default: () => []},
  },
  watch     : {},
  data() {
    return {
      isDataChanged            : false,
      isBudgetEditOverride     : false,
      // budgetYears: ['','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035','2036','2037','2038','2039','2040'],
      data                     : null,
      taskTotals               : {
        hours   : 0,
        labor   : 0,
        parts   : 0,
        supplies: 0,
        expenses: 0,
        outside : 0,
        total   : 0,
      },
      taskTotal                : 0,
      estimatedTotal           : 0,
      taskVendorLookupModalShow: false,
      vendorSearchField        : "",

      loading  : false,
      isLoading: "fa fa-spinner  fa-refresh-animate mr-3",

    }
  },
  async created() {
    this.data = this.task
    this.calculateTaskTotal();
  },
  async activated() {
  },
  mounted() {
  },
  computed: {
    descriptionRows() {
      //   if( this.ticket.status === 'complete' ) {
      //     return 4;
      //   }
      //   let result = this.ticket.description.split( "\n" );
      //   if( result.length > 12) {
      //     return 18;
      //   }
      //   if( result.length > 4) {
      //     return 12;
      //   }
      //   return 4;
      // }
      return 4;
    }
  },
  methods : {
    done() {
      if(this.data) {
        this.taskSave();
        return;
      }
      this.$emit("done");
    },
    canEditBudget() {
      if(this.isBudgetEditOverride) {
        return true;
      }
      if(Currency(this.data.hours).add( this.data.labor).add(this.data.parts).add(this.data.supplies).add(this.data.expenses).add( this.data.outside).value != 0) {
        return false;// if we have any values, budget is blocked, regardless if we can edit or not
      }
      return this.enableBudgetEdit;
    },
    calculateTaskTotal() {
      if(!this.data) {
        return;
      }
      this.taskTotal      = Currency(this.data.hours).multiply(this.data.laborRate).add(this.data.labor).add(this.data.parts).add(this.data.supplies).add(this.data.expenses).add(this.data.outside).value;
      this.estimatedTotal = Currency(this.data.estimatedHours).multiply(this.data.laborRate).add(this.data.estimatedLabor).add(this.data.estimatedParts).add(this.data.estimatedSupplies).add(this.data.estimatedExpenses).add(this.data.estimatedOutsideTotal).value;
    },
    dataChanged() {
      this.isDataChanged = true;
      this.calculateTaskTotal();
      this.$emit("changed");
    },
    async vendorSearch(s) {
      let response = await VendorService.liveSearch(s);
      return response.data;
    },
    taskSearchVendor() {
      if(this.isReadOnly()) {
        return;
      }
      this.taskVendorLookupModalShow = true;
    },
    removeVendorFromTask() {
      this.data.vendorId             = null;
      this.data.vendorName           = "";
      this.taskVendorLookupModalShow = false;
      this.dataChanged()
    },
    assignVendorToTask(e) {
      if(!e) {
        return;
      }
      this.data.vendorId             = e._id;
      this.data.vendorName           = `${e.name}${(e.primary.city) ? ' : ' + e.primary.city + ', ' + e.primary.state || '' : ''}`
      this.taskVendorLookupModalShow = false;
      this.dataChanged();
    },
    currency(c) {
      return Currency(c);
    },
    USD(value) {
      return Currency(value, {precision: 2, symbol: ' '}).format();
    },
    removeSearchQuery: function() {
      this.searchQuery = '';
      this.isResult    = false;
    },
    taskSave() {
      if(!this.data) {
        return;
      }
      this.$emit("save", this.data);
    },
    taskCancel() {
      this.$emit("cancel", this.data);
    },

    isReadOnly() {
      return !this.isEditable;
    },
    availableUsers() {
      // fixme: is this the "assignment" list and where is it used?
      // return this.users.filter((u) => { return u.ticketTypes.indexOf( this.ticketType ) >= 0 }) ;
      // return this.assignToUsers;
      return [];
    },
    toggleComplete(d) {
      this.$emit("toggleComplete", d);
    },
    tagValidator(tag) {
      return tag === tag.toLowerCase() && tag.length > 2 && tag.length < 15
    },
    moment(d) {
      return Moment(d);
    }
  }
}

</script>
<style lang="scss">

.outline-shadow {
  -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.35);
}

.collapsible {
  background-color: #eee;
  margin-top: .5em;
  border-radius: 1em;
  color: #444;
  cursor: pointer;
  padding: 10px;
  border: none;
  text-align: left;
  outline: none;
}

ins {
  background-color: rgba(0, 255, 0, .1);
  text-decoration: none;
}

del {
  background-color: rgba(255, 0, 0, .1);
}

.hover-track-light:hover {
  background: #eee;
  color: black;
}

.hover-track-dark:hover {
  background: #333;
  color: white;
}


.page-stamp {

  position: absolute;
  top: 4em;
  left: 2em;
  z-index: 1;
  font-family: Arial, sans-serif;
  -webkit-transform: rotate(-30deg); /* Safari */
  -moz-transform: rotate(-30deg); /* Firefox */
  -ms-transform: rotate(-30deg); /* IE */
  -o-transform: rotate(-30deg); /* Opera */
  transform: rotate(-30deg);
  font-size: 6em;
  color: #c00;
  background: #fff;
  border: solid 4px #c00;
  padding: 0 .5em 0 .5em;
  border-radius: .25em;
  zoom: 1;
  filter: alpha(opacity=20);
  opacity: 0.2;
  -webkit-text-shadow: 0 0 2px #c00;
  text-shadow: 0 0 2px #c00;
  box-shadow: inset 0px 0px 0px 10px #c00;
}


.page-stamp:after {
  content: '';
  border-radius: .25em;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/stamp-background.jpg");
  mix-blend-mode: lighten;
}

@media only screen and (max-device-width: 1700px) {
  .hide-too-small {
    display: none;
  }
}


@media print {

  @page {
    size: letter portrait;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    width: 100%;
  }

  .logo {
    width: 300px !important;
    max-width: 300px !important;
  }

  .noprint {
    display: none;
  }

  .container {
    width: 100%;
    max-width: 100%;
  }
}

</style>
