<template>
  <div class="content">
    <div class="container-fluid">

      <div v-if="!procedure" class="">
        <!-- <b-button class="btn btn-sm btn-fill btn-success float-left" style="position:relative;top:.3em;margin-right:1em;" @click="done">Done</b-button> -->
        <!--<b-button v-if="isDataChanged"class="btn btn-sm btn-fill btn-warning float-left" style="position:relative;top:.3em;margin-right:1em;" @click="saveProcedure">Save</b-button>-->
        <div v-if="$root.isAdmin()" style="float:right;position:relative;top:.3em;">
          <i class="fa fa-plus-square show-hand" style="font-size:1.5em;margin-right:.5em;" v-b-popover.hover.top="'Add a new article'" @click="addProcedure"></i>
          <b-checkbox v-model="isTreeView" @change="treeViewChange()">List View</b-checkbox>
        </div>

        <!--                <div>-->
        <!--                  <b-checkbox v-model="isListView">List View</b-checkbox>-->
        <!--                </div>-->

        <div v-if="isTreeView && procedures">
          <h2 style="margin:0 0 1em 0;">Knowledge Base </h2>
          <b-form-input id="filterString1" type="search" class="filter-field" style="float:left;position:relative;top:-6px;" v-model="filterString" placeholder="Filter selections" />
          <b-table striped hover head-variant="dark" stacked="md" responsive="sm" :items="procedures" :filter="filterString" :fields="procedureListFields" @row-clicked="procedureSelect">
          </b-table>
        </div>
        <!--        <div v-if="isTreeView && procedures" style="margin-top:1em;margin-left:2em;">-->
        <!--          <b-form-input id="filterString2" type="search" class="filter-field" style="float:left;position:relative;top:-6px;" v-model="filterString" placeholder="Filter selections" />-->
        <!--          <v-jstree v-if="treeView" :data="treeView" whole-row @item-click="articleClick" />-->
        <!--        </div>-->

        <div v-if="!isTreeView">
          <h2 style="margin:0;">Knowledge Base</h2>
          <p style="margin: 0 0 2em 2em;font-size:.9em;color:gray;">What are you looking for?</p>
          <div class="row">
            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-8 ">
              <live-search
                style="width:600px;"
                :searchMethod="search"
                valueField="_id"
                v-model="selectedSearchField"
                displayField="title"
                @select="searchSelect($event)"
              >
                <template v-slot:list-item="suggestion">
                  <div style="">
                    <span style="font-weight:700;">{{ suggestion.title }}</span>
                    <div v-if="suggestion.shortDescription" style="margin-left:10px;word-wrap:break-word;white-space:pre-line;">{{ suggestion.shortDescription }}</div>
                    <div style="margin-left:10px;font-size:.8em;color:gray;">Last Update: {{ moment(suggestion.updatedAt).format("LLLL") }}, by {{
                        suggestion.lastUpdateBy
                      }}
                    </div>
                  </div>
                  <!--              <document-card-->
                  <!--                :id = "suggestion._id"-->
                  <!--                :title = "suggestion.title"-->
                  <!--                :lastUpdate = "suggestion.updatedAt"-->
                  <!--                :author = "suggestion.lastUpdateBy"-->
                  <!--                />-->
                </template>
              </live-search>
            </div>
            <div class="col-4 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <div style="max-height:600px;overflow:auto;">
                <h4 style="margin:0;">Search History</h4>
                <table v-if="searchHistory.length>0" class="table responsive">
                  <thead>
                  <tr>
                    <td>Search</td>
                    <td>Document Title</td>
                  </tr>
                  </thead>
                  <tr v-for="(searchItem, searchIndex) in searchHistory" @click="searchSelect(searchItem)">
                    <td>{{ searchItem.search }}</td>
                    <td style="font-weight:700;text-wrap:wrap;">{{ searchItem.title }}</td>
                  </tr>
                  <!--                  <li >{{ searchItem.search }} : <span style="font-weight:700;">{{ searchItem.title }}</span></li>-->
                </table>
              </div>
            </div>

          </div>

        </div>

        <!--          <b-button class="btn-fill" size="sm" variant="danger" @click="taskVendorLookupModalShow=false">-->
        <!--            Cancel-->
        <!--          </b-button>-->
        <!--          <b-button class="btn-fill" size="sm" variant="info" @click="removeVendorFromTask()">-->
        <!--            Remove Vendor-->
        <!--          </b-button>-->

      </div>

      <div v-if="procedure !== null" class="">
        <checklist-editor :checklist="procedure"
                          itemName="Procedure"
                          :stepTemplate="newStepTemplate"
                          :iconOptions="iconOptions"
                          @done="procedureEditDone($event)"
                          @save="saveProcedure($event)"
                          @cancel="cancel($event)"
                          @dataChanged="dataChanged()" />
      </div>

    </div>
  </div>
</template>
<script>
import LiveSearch        from "@/components/UIComponents/Inputs/LiveSearch.vue";
import VendorService     from "@/Services/VendorService";
import Vue               from "vue";
// import TaskService       from "/src/Services/TaskService";
import ProceduresService from "/src/Services/ProceduresService";

import Moment          from "moment";
import Card            from "/src/components/UIComponents/Cards/Card.vue";
import TaskList        from "/src/components/UIComponents/Cards/TaskList.vue";
import SfInput         from "/src/components/UIComponents/Inputs/SFormInput.vue";
import ChecklistEditor from "/src/components/UIComponents/ChecklistEditor";
import DocumentCard    from "/src/components/UIComponents/Cards/DocumentCard.vue";
import VJstree         from 'vue-jstree';

export default {
  name      : "procedures",
  components: {
    DocumentCard,
    LiveSearch,
    Card,
    SfInput,
    TaskList,
    ChecklistEditor,
    VJstree
  },
  data() {
    return {
      isDataChanged: false,
      isListView   : false,
      isTreeView   : false,
      iconOptions  : [
        {value: "fa fa-forward", text: "Forward"},
        {value: "fa fa-step-forward", text: "Step Forward"},
        {value: "fa fa-stop", text: "Stop"}
      ],
      //treeView:[],
      newProcedureTemplate: {
        isTemplate      : false,
        isGlobal        : false,
        active          : true,
        version         : 1,
        title           : "",
        description     : "",
        shortDescription: "",
        category        : "procedure",
        department      : "",
        tags            : [],
        steps           : []
      },
      newStepTemplate     : {
        active                : true,
        title                 : "New Step",
        description           : "",
        note                  : "",
        sequence              : 0,
        icon                  : "fa fa-step-forward",
        imageUrl              : null,
        estimatedDurationUnit : "hour",
        estimatedDurationValue: 0,
        estimatedHours        : 0,
        items                 : []
      },
      procedureListFields : [
        {key: "category", label: "Category", sortable: true, formatter: value => { return value.charAt(0).toUpperCase() + value.slice(1) }},
        // {key: "department", label: "Department", sortable: true, formatter: value => { return value.toUpperCase()}},
        {key: "title", label: "Title", sortable: true},
        // {key: "shortDescription", label: "Description", sortable: true},
        // {key: "policyReference", label: "Reference", sortable: true},
        {key: "updatedAt", label: "Last Update", sortable: true, formatter: value => { return Moment(value).fromNow() }},
      ],
      filterString        : "",
      selectedSearchField : "",
      searchField         : "",
      searchHistory       : [],
      procedures          : [],
      treeView            : null,
      procedure           : null,
      loading             : false,
      isLoading           : "fa fa-spinner  fa-refresh-animate mr-3",
      type                : [ '', 'info', 'success', 'warning', 'danger' ],
      notifications       : {
        topCenter: false
      }
    }
  },
  async created() {
    // await this.getProcedures();
  },
  computed: {},
  methods : {
    moment(d) {
      return Moment(d);
    },
    done() {
      this.$router.go(-1);
    },
    cancel() {
      this.procedure = null;
    },
    async treeViewChange() {
      // console.log( "isTreeView", this.isTreeView );
      if(this.isTreeView) {
        await this.getProcedures();
      }
    },
    async search(s) {
      // console.log("about to search", s);
      this.searchField = s;
      let response     = await ProceduresService.search(s);
      // console.log("results", response.data);
      return response.data;
    },
    // selectProcedure(item) {
    //   this.getProcedure(item._id);
    // },
    async articleClick(v, item) {
      if(item.children && item.children.length > 0) {
        if(item.opened) {
          item.closeChildren();
        } else {
          item.openChildren();
        }
        return;
      }
      // await this.$root.alert( "Article Click" );
      this.getProcedure(item.id);
    },
    searchSelect(item) {
      let f = _.find(this.searchHistory, {_id: item._id, title: item.title});
      if(!f) {
        this.searchHistory.unshift({title: item.title, _id: item._id, search: this.searchField});
      }
      this.procedureSelect(item);
    },
    procedureSelect(item, index, event) {
      let id = item._id;
      this.getProcedure(id);
    },

    makeTreeView() {
      let x = [];

      if(!this.procedures) {
        return x;
      }
      let me          = this;
      var departments = _.uniq(this.procedures.map((d) => d.department.toUpperCase()));

      departments.forEach(d => {
        let p      = {text: d.toUpperCase(), opened: false, children: []};
        p.children = _.sortBy(this.procedures.filter(f => { return f.department.toUpperCase() == d }).map(m => { return {text: m.title, id: m._id}}), "text");

        x.push(p);
      })

      // x.forEach((item, index, object) => {
      //   if (item.children.length === 0) {
      //     object.splice(index, 1);
      //   }
      // });

      setTimeout(() => {
        me.treeView = x;
      }, 1)

      return x;
    },
    addProcedure() {
      this.procedure = Object.assign({}, this.newProcedureTemplate);
    },
    dataChanged() {
      this.isDataChanged = true;
    },
    procedureEditDone(procedure) {
      this.procedure = null;
    },
    async saveProcedure(procedure) {
      let self = this;
      try {
        let result;

        if(procedure._id) {
          result = await ProceduresService.saveProcedure(procedure);
          let i  = _.findIndex(this.procedures, {_id: procedure._id});
          if(i >= 0) {
            Vue.set(this.procedures, i, result.data);    // have Vue set this object so the action is observable
          }
        } else {
          result = await ProceduresService.addProcedure(procedure);
          this.procedures.push(result.data);     // todo: this needs tweaking...
        }

        // this is a game I'm having to play to get the treeView to properly re-render
        // the new list.
        self.treeView = null;

        setTimeout(() => {
          self.makeTreeView();
        }, 2);

        this.procedure = null;

      } catch(error) {
        console.log(error.message)
      }
    },
    async getProcedure(id) {
      try {
        let results    = await ProceduresService.getProcedure(id);
        this.procedure = results.data;

      } catch(error) {
        console.log(error.message);
      }
    },
    async getProcedures() {
      try {
        let results     = await ProceduresService.getProcedures();
        this.procedures = results.data;
        // this.makeTreeView();

      } catch(error) {
        console.log(error.message);
      }
    }
  }
}

</script>
<style lang="scss">
.procedure-edit {
  margin: 1em;
  padding: 1em;
  background: #eee;
  border-radius: 1em;
}


.procedure-steps {
  margin: 40px 0;
  padding: 40px;
  background-color: #eee;
}


@media only screen and (min-width: 320px) and (max-width: 575px) {
  .steps {
    padding: 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .steps {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
}
</style>
